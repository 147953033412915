export class Modal
{
    constructor()
    {

      const modal = document.getElementById('modal')

      document.querySelectorAll('.modal').forEach(m => {

        modal.showModal();

      })

      document.querySelectorAll("*[toggle-modal]").forEach(el => {
        el.addEventListener('click', e => {
          e.preventDefault()
          const id = el.getAttribute('toggle-modal');
          const content = el.getAttribute('toggle-modal-content');

          const modal = document.querySelector(id);

          if (modal) {
            modal.showModal();
            if (content) {
              modal.innerHTML = content
            }

            modal.addEventListener("click", evt => {
              if (evt.target === modal) {
                modal.close();
              }
            });
          }
        })
      })

    }
}
