export class Blog {


  constructor() {
    this.clickFilter()
  }

  clickFilter() {

    this.filters = document.querySelectorAll('.filter')

    this.filters.forEach(filter => {

      filter.addEventListener('click', event => {
        this.resetFilters()
        filter.classList.add('active')

        const slug = filter.getAttribute('data-slug')

        this.posts = document.querySelectorAll('.card')

        this.posts.forEach(post => {

          if (slug === "all") {
            post.classList.remove('hidden')
            post.classList.add('show')
          } else {

            if (post.getAttribute('category') === slug) {
              post.classList.remove('hidden')
              post.classList.add('show')
            } else {
              post.classList.remove('show')
              post.classList.add('hidden')
            }

          }

        })

      })
    })

  }

  resetFilters() {

    this.filters = document.querySelectorAll('.filter')

    this.filters.forEach(filter => {
      filter.classList.remove('active')
    })

  }

}
