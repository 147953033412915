import Swiper, {Pagination, Autoplay, Navigation} from "swiper";

export class Slider {
  constructor() {

    document.querySelectorAll('*[slider]').forEach(slider => {

      this.registerSlider(slider)

    })
  }

  registerSlider(slider) {

    let name = slider.getAttribute('data-slider')
    let per_view = slider.getAttribute('per-view')
    let per_view_sm = slider.getAttribute('per-view-sm')
    let nav = slider.getAttribute('navigation')

    const modules = [Pagination, Autoplay]
    let navigation = {}
    let pagination = {}
    if (nav === 'arrow') {
      modules.shift()
      modules.push(Navigation)
      navigation = {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    } else {
      pagination = {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="swiper-pagination-bullet"></span>'
        }
      }
    }

    const
      swiper = new Swiper('.' + name, {
        modules: modules,
        slidesPerView: per_view,
        loop: false,
        spaceBetween: 20,
        autoplay: {
          delay: 2500
        },
        navigation: navigation,
        pagination: pagination,
        breakpoints: {
          300: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2
          },
          1100: {
            slidesPerView: 3
          }
        }
      });

  }
}
